/* Header.css */

.appBar {
    background-color: #fff; /* Assuming white background */
    box-shadow: none;
    border-bottom: none; /* Remove border if not needed */
  }
  
  .logo {
    height: 50px; /* Adjust based on your logo's aspect ratio */
    margin-right: 20px;
  }
  
  .title {
    flex-grow: 1;
    color: #6E6E6E; /* The color seems to be a shade of grey */
    font-weight: bold; /* Assuming you want it bold */
  }
  
  .button {
    color: #6E6E6E; /* Same color as the title for uniformity */
    font-weight: 600;
    text-transform: none;
    padding: 6px 12px; /* Adjust to your preference */
    margin: 0; /* Remove margin between buttons */
    border-radius: 0; /* Material-UI buttons have a default border-radius */
  }
  
  .protocol {
    color: #000; /* Highlight the PROTOCOL MODULES text more if needed */
    position: relative;
  }
  
  .protocol::after {
    content: '';
    display: block;
    width: 100%;
    height: 3px;
    background-color: #7A5FFF; /* The purple color for the indicator */
    position: absolute;
    bottom: -1px; /* Adjust this value if the AppBar has a border */
    left: 0;
  }
  
  .caret {
    color: #7A5FFF; /* Use the same purple for the caret icon */
  }
  
  /* Add any additional styles for the ALL TRAININGS button if it has unique styles */
  